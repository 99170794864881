import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from 'src/app/shared/models/product.model';
import { Category } from 'src/app/shared/models/category.model';
import { AdminService } from 'src/app/shared';
import { ProductLine } from 'src/app/shared/models/productline.model';

export interface DialogData {
    products: Array<Product>;
    category: Category;
}

@Component({
    selector: 'app-category-product-dialog',
    templateUrl: 'category-product-dialog.html',
    styleUrls: ['./category-product-dialog.scss']
})
export class CategoryProductDialogComponent implements OnInit {

    category: Category = null;
    products: Array<Product> = [];
    productLines: Array<ProductLine> = [];
    _timeout: any = null;

    constructor(
        public dialogRef: MatDialogRef<CategoryProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private adminService: AdminService) { }

    ngOnInit() {
        this.getCategory();
        this.productLines = this.adminService.productLines.value;
        this.products = this.adminService.products.value;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getCategory() {
        this.adminService.getCategory(this.data.category.categoryid).subscribe(
            (response) => {
                console.log('get product response', response);
                this.category = response;
                this.buildProducts(); 
            }
        );
    }

    buildProducts() {
        this.products.forEach((p) => {
            p.checked = false;
        });
        for (let i = 0; i < this.products.length; i++) {
            for (let j = 0; j < this.category.productCategories.length; j++) {
                if (this.products[i].productid === this.category.productCategories[j].productid) {
                    this.products[i].checked = true;
                    this.products[i].sortnr = this.category.productCategories[j].sortnr;
                    this.products[i].priority = this.category.productCategories[j].priority;
                }
            }
        }
    }

    checkedChange(event, product) {
        console.log('checked change event: ', event);
        console.log('checked change model: ', product);
        if (event) {
            const s = product.sortnr ? product.sortnr : '3';
            const p = product.priority ? product.priority : '';
            this.adminService.addProductCategoryConnection(product.productid, this.data.category.categoryid, s, p).subscribe(
                () => {
                    // done
                }
            );
        } else {
            this.adminService.deleteProductCategoryConnection(product.productid, this.data.category.categoryid).subscribe(
                () => {
                    // done
                }
            );
        }
    }

    sortnrChange(event, product) {
        console.log('sortnr changed');
        // Debounced with this._timeout
        if (this._timeout) { // if there is already a timeout in process cancel it
            window.clearTimeout(this._timeout);
        }
        this._timeout = window.setTimeout(() => {
            this._timeout = null;
            this.adminService.addProductCategoryConnection(product.productid, this.data.category.categoryid, product.sortnr, product.priority).subscribe(
                () => {
                    // done
                }
            );
        }, 250);
    }

    getProductLineName(id) {
        let name;
        this.productLines.forEach(
            (p) => {
                if (p.productlineid === id) {
                    name = p.title_de;
                }
            }
        );
        return name;
    }

}
