import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Category } from '../shared/models/category.model';
import { AdminService } from '../shared';
import { MatDialog } from '@angular/material';
import { CategoryProductDialogComponent } from '../categories/category-product-dialog/category-product-dialog';

@Component({
  selector: 'app-category-items',
  templateUrl: './category-items.component.html',
  styleUrls: ['./category-items.component.scss']
})
export class CategoryItemsComponent implements OnInit, OnDestroy {

  @Input() categories;


  imageBaseUrl = 'https://sefarmeshapp.inaffect.one/pm/images/hk/';
  selectedCategory = {
    '1': null,
    '2': null,
    '3': null,
    '4': null
  };
  editCategory = null;
  editCat$: any;

  constructor(
    private adminService: AdminService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.editCat$ = this.adminService.editCategory.subscribe(
      (e) => {
        this.editCategory = e;
      }
    );
  }

  ngOnDestroy() {
    this.editCat$.unsubscribe();
  }

  onSelectCategory(event, category) {
    event.stopPropagation();
    if (category.level === 4) {
      return;
    }
    console.log('tapped: ', category);
    if (this.selectedCategory[category.level] === category.categoryid) {
      this.selectedCategory[category.level] = null;
    } else {
      this.selectedCategory[category.level] = category.categoryid;
    }
    console.log(JSON.stringify(this.selectedCategory));
  }

  edit(category, event) {
    console.log('edit: ', category);
    event.preventDefault();
    event.stopPropagation();
    this.adminService.setEditCategory(category);
    // window.scrollTo(0, 0);
  }

  connectProducts(category, event) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(CategoryProductDialogComponent, {
      width: '550px',
      data: { category: category, products: this.adminService.products.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  delete(category, event) {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm('Sicher, dass Sie diese Kategorie löschen wollen?')) {
      this.adminService.deleteCategory(category).subscribe(
        () => {
          // deleted
        }, (error) => {
          // not deleted
        }
      );
    }
  }

  addSubCategory(category, event) {
    event.preventDefault();
    event.stopPropagation();
    let c = new Category();
    c.parentid = category.categoryid;
    this.adminService.setEditCategory(c);
  }

}
