import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeDeCh from '@angular/common/locales/de-CH';
import localeEnGb from '@angular/common/locales/en-GB';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewComponent } from './overview/overview.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent, AuthRoutingModule, AuthService, AuthInterceptor } from './auth';
import { ErrorsComponent } from './errors/errors.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProductsComponent } from './products/products.component';
import { LocalizedDatePipe, CurrencyNumberPipe, ImagePreloadDirective, ApiService, AdminService } from './shared';
import { ProductsFormComponent } from './products/products-form/products-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';

// Angular Material Components
import {
  MatButtonModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatRippleModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatMenuModule,
  MatTooltipModule,
  MatSnackBarModule
} from '@angular/material';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { ErrorService } from './errors/errors.service';
import { NavService } from './navbar/nav.service';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryItemsComponent } from './category-items/category-items.component';
import { CategoriesFormComponent } from './categories-form/categories-form.component';
import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';
import { ArraySortPipe } from './shared/pipes/array-sort.pipe';
import { ProductLinesComponent } from './product-lines/product-lines.component';
import { ProductLinesFormComponent } from './product-lines/product-lines-form/product-lines-form.component';
import { ProductCategoryDialogComponent } from './products/product-category-dialog/product-category-dialog';
import { CategoryProductDialogComponent } from './categories/category-product-dialog/category-product-dialog';

registerLocaleData(localeEnGb);
registerLocaleData(localeDeCh);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    AuthComponent,
    ErrorsComponent,
    NavbarComponent,
    ProductsComponent,
    LocalizedDatePipe,
    CurrencyNumberPipe,
    ArraySortPipe,
    SearchFilterPipe,
    ImagePreloadDirective,
    ProductsFormComponent,
    CategoriesComponent,
    CategoryItemsComponent,
    CategoriesFormComponent,
    ProductLinesComponent,
    ProductLinesFormComponent,
    ProductCategoryDialogComponent,
    CategoryProductDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatRippleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTooltipModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ApiService,
    AuthService,
    ErrorService,
    AdminService,
    NavService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    LocalizedDatePipe,
    CurrencyNumberPipe,
    SearchFilterPipe,
    ArraySortPipe,
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'de-CH' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProductCategoryDialogComponent,
    CategoryProductDialogComponent
  ]
})
export class AppModule { }
