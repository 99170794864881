import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyNumber'
})
export class CurrencyNumberPipe implements PipeTransform {

    transform(val: number, currency: string, fractionDigits: number): string {
        if (val !== undefined && val !== null) {
            return val.toLocaleString('de-CH', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: fractionDigits
            });
        } else {
            return '';
        }
    }
}
