import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../shared/services/api.service';
import { AccessData } from './access-data.model';
import { Router } from '@angular/router';

const { log } = console;

@Injectable()
export class AuthService {
    accessData = new BehaviorSubject<AccessData>(new AccessData());
    clientName = 'sefarBackend';
    clientSecret = 'SEIa0tEuFnEqJJI30UgTdslcKBRe1245';
    referenceId = new BehaviorSubject<any>(null);

    public localUserData: any;

    constructor(
        private apiService: ApiService,
        private router: Router
    ) {
        this.localUserData = JSON.parse(localStorage.getItem('accessData'));
        console.log(this.referenceId.value);
        if (this.localUserData) {
            this.handleNextAccessData(this.localUserData);
        }
    }

    initReferenceId() {
        if (localStorage.getItem('referenceId')) {
            this.referenceId.next(localStorage.getItem('referenceId'));
        } else {
            const guid = this.makeGuid();
            localStorage.setItem('referenceId', guid);
            this.referenceId.next(guid);
        }
    }

    handleNextAccessData(accessData: AccessData) {
        localStorage.setItem('accessData', JSON.stringify(accessData));
        this.accessData.next(accessData);
    }

    login(credentials): Observable<AccessData> {
        credentials.client_id = this.clientName;
        credentials.client_secret = this.clientSecret;
        credentials.grant_type = 'password';
        return this.apiService.post('/token', credentials, 'json', false)
            .pipe(
                map(
                    data => {
                        this.handleNextAccessData(data);
                        return data;
                    }
                )
            );
    }

    register(user) {
        return this.apiService.post('/api/Account/Signup', user, 'json', false).pipe(
            map(
                (data: AccessData) => {
                    log('Create User Account Response: ' + JSON.stringify(data));
                    this.handleNextAccessData(data);

                    return data;
                }
            )
        );
    }

    getRefreshToken() {
        this.initReferenceId();
        // tslint:disable-next-line:max-line-length
        const refreshToken = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')).refresh_token : 'No refreshToken';
        const user = {
            'grant_type': 'refresh_token',
            'refresh_token': refreshToken,
            'client_id': this.clientName,
            'client_secret': this.clientSecret
        };
        return this.apiService.post('/token', user, 'json', false).pipe(
            map(
                (data) => {
                    log('RefreshToken Response: ' + JSON.stringify(data));
                    this.handleNextAccessData(data);

                    return data;
                }
            )
        );
    }

    forgotPasswordRequest(credentials) {
        return this.apiService.post('/api/Account/ForgotPassword', credentials, 'json', false).pipe(
            map(
                data => {
                    return data;
                }
            )
        );
    }

    forgotPasswordReset(credentials) {
        return this.apiService.post('/api/Account/ResetPassword', credentials, 'form', false).pipe(
            map(
                data => {
                    return data;
                }
            )
        );
    }


    logout() {
        this.accessData.next(new AccessData());
        this.referenceId.next(null);
        this.router.navigateByUrl('/auth/login');
        localStorage.removeItem('accessData');
        localStorage.removeItem('referenceId');
    }

    makeGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

}
