import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../shared/models/product.model';
import { Allergen } from '../../shared/models/allergen.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language } from '../../shared/models/language.model';
import { ProductTemplate } from '../../shared/models/product-template.model';
import { environment } from '../../../environments/environment';
import { ApiService, AdminService } from '../../shared';
import { ErrorService } from '../../errors/errors.service';
import { Error } from '../../errors/error.model';
import { map, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ProductCategoryDialogComponent } from '../product-category-dialog/product-category-dialog';

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.scss']
})
export class ProductsFormComponent implements OnInit {

  productForm: FormGroup;
  productImage: any;
  newProductImage: any;
  imageBaseUrl: any;
  imageFormData: FormData;
  uploadResponse: any;
  wrongFileType: boolean;
  uploading: boolean;
  uploadingProgress = 0;
  saving = false;
  bonPrintTime = 0;
  bonPrintTimeType = 'instant';
  bonPrintTimeInitialized = false;
  productLines = [];
  categories = [];
  categoryAreaOpen = false;

  fields = {
    productlineid: ['', Validators.required],
    thread: [''],
    cm_inch: [''],
    thread_diameter_nominal: [[]],
    weave_type: [],
    mesh_opening: [],
    open_area: [],
    mesh_thicknes: [],
    theoretical_colour_volume: [],
    categories: []
  };

  @Input() activeProduct;

  @Output()
    submitProduct: EventEmitter<Product> = new EventEmitter<Product>();
  @Output()
    productCreated: EventEmitter<Product> = new EventEmitter<Product>();
  @Output()
    productUpdated: EventEmitter<Product> = new EventEmitter<Product>();
  @Output()
    closeProductForm: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output()
    deleteProduct: EventEmitter<Product> = new EventEmitter<Product>();


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private adminService: AdminService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.productLines = this.adminService.productLines.value;
    this.categories = this.adminService.categories.value;
    this.buildProductForm();
    if (this.activeProduct && this.activeProduct.productid) {
      this.initProductForm();
    }
  }

  buildProductForm() {
    this.productForm = this.formBuilder.group(this.fields);
  }

  initProductForm() {
    const fields = JSON.parse(JSON.stringify(this.fields));
    fields.productlineid = this.activeProduct.productlineid;
    fields.thread = this.activeProduct.thread;
    fields.cm_inch = this.activeProduct.cm_inch;
    fields.thread_diameter_nominal = this.activeProduct.thread_diameter_nominal;
    fields.weave_type = this.activeProduct.weave_type;
    fields.mesh_opening = this.activeProduct.mesh_opening;
    fields.open_area = this.activeProduct.open_area;
    fields.mesh_thicknes = this.activeProduct.mesh_thicknes;
    fields.theoretical_colour_volume = this.activeProduct.theoretical_colour_volume;
    fields.categories = this.activeProduct.categories;

    this.productForm.patchValue(fields);
  }



  onSubmit() {
    this.errorService.setError(new Error());
    if (this.productForm.invalid) {
      const error: Error = {
        message: 'Bitte füllen Sie vor dem speichern alle rot markierten Felder aus.'
      };
      this.errorService.setError(error);
      return;
    }
    this.saving = true;
    const product = this.createProductObject();
    if (this.activeProduct.productid) {
      this.updateProduct(product);
    } else {
      this.createProduct(product);
    }
  }

  updateProduct(product: Product) {
    this.adminService.updateProduct(product).subscribe(
      (data) => {
        this.saving = false;
        // emit submit event to parent (ProductsComponent)
        this.productUpdated.emit(product);
      },
      (error) => {
        console.log(error);
        this.saving = false;
        this.errorService.setError({
          message: 'Produkt speichern ist fehlgeschlagen.'
        });
      }
    );
  }

  createProduct(product: Product) {
    this.adminService.createProduct(product).subscribe(
      (data) => {
        this.saving = false;
        this.productCreated.emit(product);
      },
      (error) => {
        console.log(error);
        this.saving = false;
        this.errorService.setError({
          message: 'Produkt erstellen ist fehlgeschlagen.'
        });
      }
    );
  }

  onDeleteProduct(product) {
    if (confirm('Möchten Sie das Produkt wirklich löschen?')) {
      this.adminService.deleteProduct(product).subscribe(
        () => {
          this.deleteProduct.emit(product);
        }
      );
    }
  }

  onCancel() {
    this.closeProductForm.emit(true);
  }

  createProductObject() {
    let product: Product = new Product();
    product = this.productForm.value;
    if (this.activeProduct.productid) {
      product.productid = this.activeProduct.productid;
    }

    return product;
  }

  getCategoryName(id) {
    let name = null;
    this.categories.forEach((c) => {
      if (c.categoryid === id) {
        name = c.title_de;
      }
    });
    return name;
  }

  toggleCategoriesArea() {
    this.categoryAreaOpen = !this.categoryAreaOpen;
  }

  openCategoryDialog(): void {
    const dialogRef = this.dialog.open(ProductCategoryDialogComponent, {
      width: '360px',
      data: { product: this.activeProduct, categories: this.categories }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


}
