import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from 'src/app/shared/models/product.model';
import { Category } from 'src/app/shared/models/category.model';
import { AdminService } from 'src/app/shared';

export interface DialogData {
    product: Product;
    categories: Array<Category>;
}

@Component({
    selector: 'app-product-category-dialog',
    templateUrl: 'product-category-dialog.html',
    styleUrls: ['./product-category-dialog.scss']
})
export class ProductCategoryDialogComponent implements OnInit {

    categories: Array<Category> = [];
    selectedCategory = {
        '1': null,
        '2': null,
        '3': null,
        '4': null
    };
    product: Product = null;

    constructor(
        public dialogRef: MatDialogRef<ProductCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private adminService: AdminService) { }

    ngOnInit() {
        this.getProduct();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSelectCategory(event, category) {
        event.stopPropagation();
        if (category.level === 4) {
            return;
        }
        console.log('tapped: ', category);
        if (this.selectedCategory[category.level] === category.categoryid) {
            this.selectedCategory[category.level] = null;
        } else {
            this.selectedCategory[category.level] = category.categoryid;
        }
        console.log(JSON.stringify(this.selectedCategory));
    }

    buildCategories(categories) {
        this.categories = null;
        this.categories = [];
        const categoriesObj = [];
        // make sure all subcategories are cleared
        categories.forEach(c => {
            c.subCategories = null;
            c.checked = false;
            // assign checked categories
            this.product.categories.forEach((id) => {
                if (c.categoryid === id) {
                    c.checked = true;
                }
            });
        });
        categories.forEach(c => {
            // identify firs level
            if (c.parentid === '-1') {
                c.level = 1;
                categories.forEach(d => {
                    // second level
                    if (c.categoryid === d.parentid) {
                        d.level = 2;
                        if (!c.subCategories) { c.subCategories = []; }
                        c.subCategories.push(d);
                        categories.forEach(e => {
                            // third level
                            if (d.categoryid === e.parentid) {
                                e.level = 3;
                                if (!d.subCategories) { d.subCategories = []; }
                                d.subCategories.push(e);
                                categories.forEach(f => {
                                    // fourth level
                                    if (e.categoryid === f.parentid) {
                                        f.level = 4;
                                        if (!e.subCategories) { e.subCategories = []; }
                                        e.subCategories.push(f);
                                    }
                                });
                            }
                        });
                    }
                });

                categoriesObj.push(c);
            }
        });

        console.log('built categories do look like this yo: ', categoriesObj);
        this.categories = categoriesObj;
    }

    getProduct() {
        this.adminService.getProduct(this.data.product.productid).subscribe(
            (response) => {
                console.log('get product response', response);
                this.product = response;
                this.buildCategories(this.data.categories);
            }
        );
    }

    checkedChange(event, category) {
        console.log('checked change event: ', event);
        console.log('checked change model: ', category);
        if (event) {
            this.adminService.addProductCategoryConnection(this.data.product.productid, category.categoryid, category.sortnr, category.priority).subscribe(
                () => {
                    // done
                }
            );
        } else {
            this.adminService.deleteProductCategoryConnection(this.data.product.productid, category.categoryid).subscribe(
                () => {
                    // done
                }
            );
        }
    }

}
