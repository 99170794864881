import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
  public bearerToken: string;
  public currentUser: any;
  loading = false;

  constructor(
    private http: HttpClient
  ) { }

  setHeaders(headerType, authenticate) {
    const headersConf = {};
    headersConf['Accept-Language'] = 'de';
    if (headerType === 'json') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'json-x') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'form') {
      headersConf['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (headerType === 'multipart') {
      headersConf['Content-Type'] = 'multipart/form-data';
    } else if (headerType === 'json-text') {
      headersConf['Content-Type'] = 'application/json';
    }
    // Authorization
    console.log(authenticate, localStorage.getItem('accessData'));
    if (authenticate) {
      if (localStorage.getItem('accessData')) {
        const accessData = JSON.parse(localStorage.getItem('accessData'));
        headersConf['Authorization'] = 'bearer ' + accessData.accessToken;
      }
    }
    return new HttpHeaders(headersConf);
  }

  formatErrors(error: any) {
    console.log('format errors');
    return Observable.throw(error.json());
  }


  get(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.api_url + path, { headers });
  }

  getTxt(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.api_url + path, { headers, responseType: 'text' });
  }

  patch(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.patch(environment.api_url + path, body, { headers });
  }

  post(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { headers });
  }

  upload(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.request('POST', environment.api_url + path, { body, reportProgress: true } );
  }

  put(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.put(environment.api_url + path, body, { headers });
  }

  delete(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.delete(environment.api_url + path, { headers });
  }

}
