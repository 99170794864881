import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Category } from '../shared/models/category.model';
import { AdminService } from '../shared';

@Component({
  selector: 'app-categories-form',
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss']
})
export class CategoriesFormComponent implements OnInit {

  category: Category = new Category();
  fields = {
    categoryid: null,
    title_de: null,
    title_en: null,
    image: null,
    sortnr: null,
    parentid: '-1',
    subCategories: null,
    level: '1'
  };
  categoryForm: FormGroup;
  category$: any;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.buildCategoryForm();
    this.category = this.adminService.editCategory.value;
    console.log('slödjkfökljfds', this.category);
    if (this.category && (this.category.categoryid || this.category.parentid)) {
      this.initCategoryForm();
    }
    this.category$ = this.adminService.editCategory.subscribe(
      (e) => {
        if (this.category === e) {
          return;
        }
        console.log(e);
        if (e) {
          if (this.category !== e) {
            this.category = e;
          }
        } else {
          this.category = new Category();
        }

        if (this.category && (this.category.categoryid || this.category.parentid)) {
          this.initCategoryForm();
        }
      }
    );

  }

  buildCategoryForm() {
    this.categoryForm = this.formBuilder.group(this.fields);
  }

  initCategoryForm() {
    const fields = JSON.parse(JSON.stringify(this.fields));
    fields.categoryid = this.category.categoryid;
    fields.title_de = this.category.title_de;
    fields.title_en = this.category.title_en;
    fields.image = this.category.image;
    fields.sortnr = this.category.sortnr;
    fields.parentid = this.category.parentid;
    // fields.subCategories = this.category.subCategories;
    fields.level = this.category.level;


    this.categoryForm.patchValue(fields);
  }

  onSubmit() {
    if (this.category.categoryid) {
      // Update
      this.adminService.updateCategory(this.categoryForm.value).subscribe(
        (data) => {
          console.log('category updated!', data);
        }, (error) => {
          console.log('category updated error!', error);
        }
      );
    } else {
      // create
      this.adminService.createCategory(this.categoryForm.value).subscribe(
        (data) => {
          console.log('category created!', data);
          this.buildCategoryForm();
          this.initCategoryForm();
        }, (error) => {
          console.log('category creation error!', error);
        }
      );
    }

  }

  onCancel() {

  }

  onDeleteCategory() {

  }

}
