import { ProductCategory } from 'src/app/shared/models/product-category.model';

export class Category {
    categoryid: string;
    title_de: string;
    title_en: string;
    image: string;
    sortnr: string;
    parentid: string;
    subCategories?: Array<Category>;
    level: number;
    productCategories?: Array<ProductCategory>;
}
