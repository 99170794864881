import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../shared';
import { Category } from '../shared/models/category.model';
import { NavService } from '../navbar/nav.service';
import { Product } from '../shared/models/product.model';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {

  cat$: any;
  categories: Array<Category> = [];
  selectedCategory: Category = null;
  loading = {
    categories: false
  };
  loadingError: any = null;
  showNewForm = false;
  showForm = false;
  editCategory: Category = null;
  editCategory$: any;
  buildingCategories = false;

  @ViewChild('formEl') formEl: ElementRef;
  productsSubscription: any;
  products: Array<Product> = [];

  constructor(
    private adminService: AdminService,
    private navService: NavService
  ) { }

  ngOnInit() {
    this.navService.setActiveNavItem('categories');
    console.log('init categories.component, categories length: ' + this.adminService.categories.value.length);
    if (this.adminService.categories.value.length < 1) {
      this.getCategories();
    }
    this.cat$ = this.adminService.categories.subscribe(
      (c) => {
        console.log('category subscription triggerd', c);
        if (c.length > 0) {
          this.buildCategories(c);
        }

        // this.categories = c;
      }
    );
    this.editCategory$ = this.adminService.editCategory.subscribe(
      (e) => {
        if (e) {
          if (e !== this.editCategory) {
            this.onEditCategory(e);
          }
        }
      }
    );
    this.productsSubscription = this.adminService.products.subscribe(
      (data) => {
        this.products = data;
      }
    );
    // load data for dialog use only
    this.adminService.getProducts().subscribe(
      () => {
        // gotten
      }
    );
    this.adminService.getProductLines().subscribe(
      () => {
        // gotten
      }
    );
  }

  ngOnDestroy() {
    console.log('destroy categories.component');
    this.cat$.unsubscribe();
    this.editCategory$.unsubscribe();
  }

  buildCategories(categories) {
    this.categories = null;
    this.categories = [];
    const categoriesObj = [];
    // make sure all subcategories are cleared
    categories.forEach(c => {
      c.subCategories = null;
    });
    categories.forEach(c => {
      // identify firs level
      if (c.parentid === '-1') {
        c.level = 1;
        categories.forEach(d => {
          // second level
          if (c.categoryid === d.parentid) {
            d.level = 2;
            if (!c.subCategories) { c.subCategories = []; }
            c.subCategories.push(d);
            categories.forEach(e => {
              // third level
              if (d.categoryid === e.parentid) {
                e.level = 3;
                if (!d.subCategories) { d.subCategories = []; }
                d.subCategories.push(e);
                categories.forEach(f => {
                  // fourth level
                  if (e.categoryid === f.parentid) {
                    f.level = 4;
                    if (!e.subCategories) { e.subCategories = []; }
                    e.subCategories.push(f);
                  }
                });
              }
            });
          }
        });

        categoriesObj.push(c);
      }
    });

    this.categories = categoriesObj;
  }

  getCategories() {
    this.loading.categories = true;
    this.adminService.getCategories().subscribe(
      (c) => {
        this.loading.categories = false;
        console.log('c: ', c);
      }, (err) => {
        this.loadingError = err;
        this.loading.categories = false;
      }
    );
  }

  onNewCategory() {
    this.adminService.setEditCategory(null);
    if (this.showNewForm) {
      // new was open, keep it closed
      this.showForm = false;
      this.showNewForm = false;
    } else {
      this.showForm = false;
      this.showNewForm = true;
      const category = new Category();
      category.parentid = '-1';
      this.openForm(category);
    }
  }



  onEditCategory(category) {
    console.log('edit this: ', category);
    this.showForm = false;
    this.showNewForm = false;
    if (this.editCategory && this.editCategory.categoryid === category.categoryid && !category.parentid) {
      this.editCategory = new Category();
    } else {
      this.openForm(category);
    }
  }

  openForm(category?) {
    this.editCategory = new Category();
    if (category) {
      this.editCategory = category;
    } else {
      this.editCategory = new Category();
    }
    this.adminService.setEditCategory(this.editCategory);
    console.log('editCategory: ', this.editCategory);
    setTimeout(() => {
      this.showForm = true;
    }, 50);
    setTimeout(() => {
      const targetElement = this.formEl.nativeElement;
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start'  });
    }, 250);
  }

  onSubmit(category) {
    console.log(category);
    this.showForm = false;
  }

  onCreated(category) {
    console.log(category);
    this.showNewForm = false;
    this.showForm = false;
    this.selectedCategory = new Category();
  }

  onCategoryUpdated(category) {
    console.log(category);
    this.showNewForm = false;
    this.showForm = false;
    this.selectedCategory = new Category();
  }

  onCloseForm(doClose: boolean) {
    this.showForm = !doClose;
    // this.showForm = false;
    this.showNewForm = false;
  }

  onDelete(category) {
    console.log('splice this category from array: ', category);
  }

}
