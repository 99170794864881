import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../shared/models/product.model';
import { Allergen } from '../../shared/models/allergen.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language } from '../../shared/models/language.model';
import { ProductTemplate } from '../../shared/models/product-template.model';
import { environment } from '../../../environments/environment';
import { ApiService, AdminService } from '../../shared';
import { ErrorService } from '../../errors/errors.service';
import { Error } from '../../errors/error.model';
import { map, catchError } from 'rxjs/operators';
import { ProductLine } from 'src/app/shared/models/productline.model';

@Component({
  selector: 'app-product-lines-form',
  templateUrl: './product-lines-form.component.html',
  styleUrls: ['./product-lines-form.component.scss']
})
export class ProductLinesFormComponent implements OnInit {

  productForm: FormGroup;
  productImage: any;
  newProductImage: any;
  imageBaseUrl: any;
  imageFormData: FormData;
  uploadResponse: any;
  wrongFileType: boolean;
  uploading: boolean;
  uploadingProgress = 0;
  saving = false;
  bonPrintTime = 0;
  bonPrintTimeType = 'instant';
  bonPrintTimeInitialized = false;
  productLines = [];
  categories = [];

  fields = {
    'title_de': ['', Validators.required],
    'title_en': [''],
    'colorcode': ['']
  };

  @Input() activeProduct;

  @Output()
    submitProduct: EventEmitter<ProductLine> = new EventEmitter<ProductLine>();
  @Output()
    productCreated: EventEmitter<ProductLine> = new EventEmitter<ProductLine>();
  @Output()
    productUpdated: EventEmitter<ProductLine> = new EventEmitter<ProductLine>();
  @Output()
    closeProductForm: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output()
    deleteProduct: EventEmitter<ProductLine> = new EventEmitter<ProductLine>();


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private adminService: AdminService,
    private errorService: ErrorService
  ) {

  }

  ngOnInit() {
    this.buildProductForm();
    if (this.activeProduct && this.activeProduct.productlineid) {
      this.initProductForm();
    }
  }

  buildProductForm() {
    this.productForm = this.formBuilder.group(this.fields);
  }

  initProductForm() {
    const fields = JSON.parse(JSON.stringify(this.fields));
    fields.title_de = this.activeProduct.title_de;
    fields.title_en = this.activeProduct.title_en;
    fields.colorcode = this.activeProduct.colorcode;

    this.productForm.patchValue(fields);
  }



  onSubmit() {
    this.errorService.setError(new Error());
    if (this.productForm.invalid) {
      const error: Error = {
        message: 'Bitte füllen Sie vor dem speichern alle rot markierten Felder aus.'
      };
      this.errorService.setError(error);
      return;
    }
    this.saving = true;
    const product = this.createProductObject();
    if (this.activeProduct.productlineid) {
      this.updateProduct(product);
    } else {
      this.createProduct(product);
    }
  }

  updateProduct(product: ProductLine) {
    this.adminService.updateProductLine(product).subscribe(
      (data) => {
        this.saving = false;
        // emit submit event to parent (ProductsComponent)
        this.productUpdated.emit(product);
      },
      (error) => {
        console.log(error);
        this.saving = false;
        this.errorService.setError({
          message: 'Produkt speichern ist fehlgeschlagen.'
        });
      }
    );
  }

  createProduct(product: ProductLine) {
    this.adminService.createProductLine(product).subscribe(
      (data) => {
        this.saving = false;
        this.productCreated.emit(product);
      },
      (error) => {
        console.log(error);
        this.saving = false;
        this.errorService.setError({
          message: 'Produkt erstellen ist fehlgeschlagen.'
        });
      }
    );
  }

  onDeleteProduct(product) {
    if (confirm('Möchten Sie das Produkt wirklich löschen?')) {
      this.adminService.deleteProductLine(product).subscribe(
        () => {
          this.deleteProduct.emit(product);
        }
      );
    }
  }

  onCancel() {
    this.closeProductForm.emit(true);
  }

  createProductObject() {
    let product: ProductLine = new ProductLine();
    product = this.productForm.value;
    if (this.activeProduct.productlineid) {
      product.productlineid = this.activeProduct.productlineid;
    }

    return product;
  }


}
