export class User {
  ".expires": string;
  ".issued": string;
  access_token: string;
  companyId: string;
  expires_in: number;
  isAdmin: boolean;
  isSysAdmin: boolean;
  isUser: boolean;
  token_type: string;
  userName: string;
}
