import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Location } from '../models/location.model';
import { PaginatedOrders } from '../models/paginated-orders.model';
import { LocationRating } from '../models/location-rating.model';
import { Allergen } from '../models/allergen.model';
import { Product } from '../models/product.model';
import { OrderSearchParams } from '../models/order-search-params.model';
import { ProductTemplate } from '../models/product-template.model';
import { Item } from '../models/opening-hours.model';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category.model';
import { ProductLine } from '../models/productline.model';

const { log } = console;

@Injectable()
export class AdminService {


    products = new BehaviorSubject<Array<Product>>([]);
    editedProduct = new BehaviorSubject<Product>(new Product());
    categories = new BehaviorSubject<Array<Category>>([]);
    editCategory = new BehaviorSubject<Category>(null);
    editProductLine = new BehaviorSubject<ProductLine>(null);
    editProduct = new BehaviorSubject<Product>(null);
    productLines = new BehaviorSubject<Array<ProductLine>>([]);

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) { }

    getCategories() {
        return this.apiService.get('/categories', 'json', true).pipe(
            map(
                data => {
                    if (data) {
                        this.categories.next(data);
                    }
                    return data;
                }
            )
        );
    }

    getProductLines() {
        return this.apiService.get('/productlines', 'json', true).pipe(
            map(
                data => {
                    if (data) {
                        this.productLines.next(data);
                    }
                    return data;
                }
            )
        );
    }

    getProducts() {
        return this.apiService.get('/products', 'json', true).pipe(
            map(
                data => {
                    if (data) {
                        this.products.next(data);
                    }
                    return data;
                }
            )
        );
    }
    getProduct(id) {
        return this.apiService.get('/products/' + id, 'json', true).pipe(
            map(
                data => {
                    return data;
                }
            )
        );
    }
    getCategory(id) {
        return this.apiService.get('/categories/' + id, 'json', true).pipe(
            map(
                data => {
                    return data;
                }
            )
        );
    }

    refreshCategories() {
        this.getCategories().subscribe(
            () => {
                // refreshed
            }, (err) => {
                // refresherror
            }
        );
    }
    refreshProductLines() {
        this.getProductLines().subscribe(
            () => {
                // refreshed
            }, (err) => {
                // refresherror
            }
        );
    }
    refreshProducts() {
        this.getProducts().subscribe(
            () => {
                // refreshed
            }, (err) => {
                // refresherror
            }
        );
    }

    setEditCategory(category) {
        if (JSON.stringify(category) !== JSON.stringify(this.editCategory.value)) {
            this.editCategory.next(category);
        } else {
            console.log('this category was already set.');
        }
    }
    createCategory(category) {
        return this.apiService.post('/categories', category, 'json', true).pipe(
            map(
                data => {
                    this.refreshCategories();
                    return data;
                }
            )
        );
    }
    updateCategory(category) {
        return this.apiService.put('/categories', category, 'json', true).pipe(
            map(
                data => {
                    this.refreshCategories();
                    return data;
                }
            )
        );
    }
    deleteCategory(category) {
        return this.apiService.delete('/categories/' + category.categoryid, {}, 'json', true).pipe(
            map(
                data => {
                    this.refreshCategories();
                    return data;
                }
            )
        );
    }

    updateProductSortNr(product) {
        return this.apiService.put('/productssortnurmbn', product, 'json', true).pipe(
            map(
                data => {
                    return data;
                }
            )
        );
    }


    setEditProductLine(productLine) {
        if (JSON.stringify(productLine) !== JSON.stringify(this.editProductLine.value)) {
            this.editProductLine.next(productLine);
        } else {
            console.log('this productLine was already set.');
        }
    }
    createProductLine(productLine) {
        return this.apiService.post('/productlines', productLine, 'json', true).pipe(
            map(
                data => {
                    this.refreshProductLines();
                    return data;
                }
            )
        );
    }
    updateProductLine(productLine) {
        return this.apiService.put('/productlines', productLine, 'json', true).pipe(
            map(
                data => {
                    this.refreshProductLines();
                    return data;
                }
            )
        );
    }
    deleteProductLine(productLine) {
        return this.apiService.delete('/productlines/' + productLine.productlineid, {}, 'json', true).pipe(
            map(
                data => {
                    this.refreshProductLines();
                    return data;
                }
            )
        );
    }


    setEditProduct(product) {
        if (JSON.stringify(product) !== JSON.stringify(this.editProduct.value)) {
            this.editProduct.next(product);
        } else {
            console.log('this product was already set.');
        }
    }
    createProduct(product) {
        return this.apiService.post('/products', product, 'json', true).pipe(
            map(
                data => {
                    this.refreshProducts();
                    return data;
                }
            )
        );
    }
    updateProduct(product) {
        return this.apiService.put('/products', product, 'json', true).pipe(
            map(
                data => {
                    this.refreshProducts();
                    return data;
                }
            )
        );
    }
    deleteProduct(product) {
        return this.apiService.delete('/products/' + product.productid, {}, 'json', true).pipe(
            map(
                data => {
                    this.refreshProducts();
                    return data;
                }
            )
        );
    }

    addProductCategoryConnection(productid, categoryid, sortnr, priority) {
        return this.apiService.post('/products/' + productid + '/' + categoryid + '/' + sortnr + '/' + priority, {}, 'json', true).pipe(
            map(
                data => {
                    // this.refreshProducts();
                    return data;
                }
            )
        );
    }
    deleteProductCategoryConnection(productid, categoryid) {
        return this.apiService.delete('/products/' + productid + '/' + categoryid, {}, 'json', true).pipe(
            map(
                data => {
                    // this.refreshProducts();
                    return data;
                }
            )
        );
    }



}
