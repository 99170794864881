import { Component, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { Location } from '../shared/models/location.model';
import { AdminService } from '../shared/services/admin.service';
import { AccessData } from '../auth/access-data.model';
import { AuthService } from '../auth';
import { Router } from '@angular/router';
import { NavService } from './nav.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { slideInLeft } from 'ng-animate';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('logoAnimation', [transition('void => *', useAnimation(slideInLeft, { params: { timing: 0.400, delay: 0 } }))])
  ]
})
export class NavbarComponent implements OnInit, OnDestroy {

  locationsSubscription: any;
  locations: Array<Location> = [];
  loading = {
    locations: false
  };
  accessData: AccessData;
  accessDataSubscription: any;
  selectedLocation: Location;
  selectedLocationSubscription: any;
  navItems: any;
  activeNavItem: any;
  activeNavItemSubscription: any;
  showLogo = false;
  yOffset = 0;



  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private navService: NavService,
    private router: Router
  ) {
    this.navItems = navService.navItems;
  }

  ngOnInit() {
    // window.addEventListener('scroll', this.scrollEvent, true);
    this.activeNavItemSubscription = this.navService.activeNavItem.subscribe(
      (navItem) => {
        if (navItem) {
          this.activeNavItem = navItem;
        }
      }
    );
    this.accessDataSubscription = this.authService.accessData.subscribe(
      (data) => {
        this.accessData = data;
      }
    );
    setTimeout(() => {
      this.showLogo = true;
    }, 1000);
  }

  ngOnDestroy() {
    this.activeNavItemSubscription.unsubscribe();
    this.accessDataSubscription.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.yOffset = window.pageYOffset;
  }

  onTapLogout() {
    this.authService.logout();
  }

  goToProfile() {
    this.router.navigateByUrl('/profile');
  }

  scrollEvent(event) {
    console.log('scrollevent', event);
    this.yOffset = window.pageYOffset;
    console.log(this.yOffset);
  }

}
