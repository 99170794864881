import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../shared/services/admin.service';
import { NavService } from '../navbar/nav.service';
import { environment } from '../../environments/environment';
import { ErrorService } from '../errors/errors.service';
import { Error } from '../errors/error.model';
import { MatDialog } from '@angular/material';
import { Product } from '../shared/models/product.model';
import { Allergen } from '../shared/models/allergen.model';
import { ProductTemplate } from '../shared/models/product-template.model';
import { Language } from '../shared/models/language.model';
import { Category } from '../shared/models/category.model';
import { ProductLine } from '../shared/models/productline.model';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

  searchText: any;
  products: Array<Product> = [];
  productsSubscription: any;
  location: Location;
  locationSubscription: any;
  selectedProduct: Product;
  loading = {
    products: true,
    categories: true,
    productLines: true
  };
  showProductForm = false;
  showNewProductForm = false;
  allergens: Array<Allergen> = [];
  allergensSubscription: any;
  productTemplate: ProductTemplate;
  languages: Array<Language> = [];
  productTemplateSubscription: any;
  metaData: any;
  imageBaseUrl: string;
  groupedProducts = [];
  categories$: any;
  categories: Array<Category> = [];
  productLines$: any;
  productLines: Array<ProductLine> = [];

  @ViewChild('formEl') formEl: ElementRef;

  constructor(
    private adminService: AdminService,
    private navService: NavService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.navService.setActiveNavItem('products');
    this.adminService.getProducts().subscribe(
      () => {
        // gotten
        this.loading.products = false;
      }
    );
    this.adminService.getCategories().subscribe(
      () => {
        // gotten
        this.loading.categories = false;
      }
    );
    this.adminService.getProductLines().subscribe(
      () => {
        // gotten
        this.loading.productLines = false;
      }
    );
    this.productsSubscription = this.adminService.products.subscribe(
      (data) => {
        this.products = data;
        this.showNewProductForm = this.showProductForm = false;
        this.selectedProduct = new Product();
      }
    );
    this.categories$ = this.adminService.categories.subscribe(
      (data) => {
        this.categories = data;
      }
    );
    this.productLines$ = this.adminService.productLines.subscribe(
      (data) => {
        this.productLines = data;
      }
    );
  }

  ngOnDestroy() {
    this.productsSubscription.unsubscribe();
  }

  searchProducts() {
    console.log('do product search');
  }

  onNewProduct() {
    if (this.showNewProductForm) {
      // new was open, keep it closed
      this.showProductForm = false;
      this.showNewProductForm = false;
    } else {
      this.showProductForm = false;
      this.showNewProductForm = true;
      this.openProductForm(new Product());
    }
  }

  editProduct(product, event) {
    this.showProductForm = false;
    this.showNewProductForm = false;
    if (this.selectedProduct && this.selectedProduct.productid === product.productid) {
      this.selectedProduct = new Product();
    } else {
      this.openProductForm(product);
    }
  }

  openProductForm(product?) {
    this.selectedProduct = new Product();
    if (product) {
      this.selectedProduct = product;
    } else {
      this.selectedProduct = new Product();
    }
    console.log('edit product: ', this.selectedProduct);
    setTimeout(() => {
      this.showProductForm = true;

    }, 50);
    setTimeout(() => {
      const targetElement = this.formEl.nativeElement;
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 250);
  }

  onSubmitProduct(product) {
    console.log(product);
    this.showProductForm = false;
  }

  onProductCreated(product) {
    console.log(product);
    this.showNewProductForm = false;
    this.showProductForm = false;
    this.selectedProduct = new Product();
    this.adminService.getProducts().subscribe(
      () => {
        // gotten
        this.loading.products = false;
      }
    );
  }

  onProductUpdated(product) {
    console.log(product);
    this.showNewProductForm = false;
    this.showProductForm = false;
    this.selectedProduct = new Product();
    this.adminService.getProducts().subscribe(
      () => {
        // gotten
        this.loading.products = false;
      }
    );
  }

  onCloseProductForm(doClose: boolean) {
    this.showProductForm = !doClose;
    // this.showProductForm = false;
    this.showNewProductForm = false;
    this.selectedProduct = new Product();
  }

  deleteProduct(product) {
    if (window.confirm('Sicher, dass Sie dieses Produkt löschen möchten?')) {
      this.adminService.deleteProduct(product).subscribe(
        () => {
          // done
        }
      );
    }
  }

  getProductLineName(id) {
    let name;
    this.productLines.forEach(
      (p) => {
        if (p.productlineid === id) {
          name = p.title_de;
        }
      }
    );
    return name;
  }



}
