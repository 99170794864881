import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AuthService } from './auth.service';
import { trigger, transition, useAnimation, query, style, stagger, animate, keyframes } from '@angular/animations';
import { bounce, bounceInDown, bounceInUp, slideInDown } from 'ng-animate';


const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    trigger('logoAnimation', [transition('void => *', useAnimation(slideInDown, { params: { timing: 0.45, delay: 0 }}))]),
    trigger('formAnimation', [transition('void => 1', useAnimation(bounceInDown, { params: { timing: 1.2, delay: 0.3 }}))]),
  ]
})
export class AuthComponent implements OnInit {
  authType: String = '';
  title: String = '';
  isSubmitting = false;
  authForm: FormGroup;
  forgotPasswordRequestSent = false;
  userId: String = '';
  confirmCode: String = '';
  passwordResetted = false;

  // animation triggers
  bounce: any;
  authBoxAnimation: any;
  logoAnimation: any;
  formAnimation: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    // use FormBuilder to create a form group
    this.authForm = this.fb.group({
      'username': ['', Validators.required]
    });
  }

  ngOnInit() {
    console.log('auth component init.');
    this.logoAnimation = 'init';
    this.formAnimation = 'init';
    this.route.url.subscribe(data => {
      console.log(data);
      // Get the last piece of the URL (it's either 'login' or 'register')
      this.authType = data[data.length - 1].path;
      // Set a title for the page accordingly
      this.title = (this.authType === 'login') ? 'Sign In' : 'Sign Up';
      // add form control for username if this is the register page
      if (this.authType === 'login') {
        this.authForm.addControl('password', new FormControl('', Validators.required));
      }
      if (this.authType === 'reset-password') {
        this.authForm.addControl('password', new FormControl('', Validators.required));
      }
      if (this.authType === 'reset-password') {
        this.authForm.removeControl('username');
      }
      if (this.authType === 'forgot') {
        this.authForm.removeControl('password');
      }
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.userId = params['userId'];
      this.confirmCode = params['confirmCode'];
    });
  }

  submitForm(form) {
    console.log(form);
    if (!form.valid) { return; }
    this.isSubmitting = true;
    if (this.authType === 'login') {
      const credentials = {
        username: this.authForm.value.username,
        password: this.authForm.value.password,
      };
      this.authService.login(credentials)
      .subscribe(
        data => {
          this.router.navigateByUrl('categories');
        },
        error => {
          console.log('login Failed');
          this.isSubmitting = false;
        }
      );
    }
    if (this.authType === 'forgot') {
      const credentials = {
        username: this.authForm.value.username
      };
      console.log(credentials);
      this.authService.forgotPasswordRequest(credentials)
      .subscribe(
        data => {
          this.forgotPasswordRequestSent = true;
          this.isSubmitting = false;
        },
        err => {
          this.forgotPasswordRequestSent = true;
          this.isSubmitting = false;
        }
      );
    }
    if (this.authType === 'reset-password') {
      const credentials = `newPassword=${this.authForm.value.password}&userId=${this.userId}&confirmCode=${this.confirmCode}`;
      this.authService.forgotPasswordReset(credentials)
      .subscribe(
        data => {
          this.passwordResetted = true;
          this.isSubmitting = false;
        },
        err => {
          this.isSubmitting = false;
        }
      );
    }
  }

}
