import { Language } from './language.model';

export class Product {
    productid: string;
    productlineid: string;
    thread: string;
    cm_inch: string;
    thread_diameter_nominal: string;
    weave_type: string;
    mesh_opening: string;
    open_area: string;
    mesh_thicknes: string;
    theoretical_colour_volume: string;
    categories: string[];
    checked?: boolean;
    sortnr?: string;
    priority?: string;
}
