import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceInDown, bounceInUp, slideInDown } from 'ng-animate';
import { ErrorService } from './errors.service';
import { Error } from './error.model';


@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
  animations: [
    trigger('errorEntryAnimation', [transition('* => *', useAnimation(bounce, { params: { timing: 0.5, delay: 0 } }))]),
  ]
})
export class ErrorsComponent implements OnInit, OnDestroy {
  errorAnimation = 'errorEntryAnimation';
  error: Error;
  errorServiceSubscription: any;

  constructor(
    private errorService: ErrorService
  ) {

  }

  ngOnInit() {
    this.errorServiceSubscription = this.errorService.error.subscribe(
      (error) => {
        console.log('new error', error);
        this.error = error;
      }
    );
  }

  ngOnDestroy() {
    this.errorServiceSubscription.unsubscribe();
  }

  get errorList() { return this.error; }

  onCloseError() {
    this.errorService.setError(new Error());
  }
}
