import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../shared/services/admin.service';
import { NavService } from '../navbar/nav.service';
import { environment } from '../../environments/environment';
import { ErrorService } from '../errors/errors.service';
import { Error } from '../errors/error.model';
import { MatDialog } from '@angular/material';
import { Product } from '../shared/models/product.model';
import { Allergen } from '../shared/models/allergen.model';
import { ProductTemplate } from '../shared/models/product-template.model';
import { Language } from '../shared/models/language.model';
import { Category } from '../shared/models/category.model';
import { ProductLine } from '../shared/models/productline.model';

@Component({
  selector: 'app-product-lines',
  templateUrl: './product-lines.component.html',
  styleUrls: ['./product-lines.component.scss']
})
export class ProductLinesComponent implements OnInit, OnDestroy {

  searchText: any;
  selectedProductLine: ProductLine;
  loading = {
    productLines: true
  };
  showProductForm = false;
  showNewProductForm = false;
  imageBaseUrl: string;
  productLines$: any;
  productLines: Array<ProductLine> = [];

  @ViewChild('formEl') formEl: ElementRef;

  constructor(
    private adminService: AdminService,
    private navService: NavService,
    private errorService: ErrorService,
    private dialog: MatDialog
  ) {
    
  }

  ngOnInit() {
    this.navService.setActiveNavItem('product-lines');
    this.adminService.getProductLines().subscribe(
      () => {
        // gotten
        this.loading.productLines = false;
      }
    );
    this.productLines$ = this.adminService.productLines.subscribe(
      (data) => {
        this.productLines = data;
        this.showNewProductForm = this.showProductForm = false;
        this.selectedProductLine = new ProductLine();
      }
    );
  }

  ngOnDestroy() {
    this.productLines$.unsubscribe();
  }

  searchProducts() {
    console.log('do product search');
  }

  onNewProduct() {
    if (this.showNewProductForm) {
      // new was open, keep it closed
      this.showProductForm = false;
      this.showNewProductForm = false;
    } else {
      this.showProductForm = false;
      this.showNewProductForm = true;
      this.openProductForm(new Product());
    }
  }

  editProduct(product, event) {
    this.showProductForm = false;
    this.showNewProductForm = false;
    if (this.selectedProductLine && this.selectedProductLine.productlineid === product.productlineid) {
      this.selectedProductLine = new ProductLine();
    } else {
      this.openProductForm(product);
    }
  }

  openProductForm(product?) {
    this.selectedProductLine = new ProductLine();
    if (product) {
      this.selectedProductLine = product;
    } else {
      this.selectedProductLine = new ProductLine();
    }
    console.log('edit product: ', this.selectedProductLine);
    setTimeout(() => {
      this.showProductForm = true;
    }, 50);
    setTimeout(() => {
      const targetElement = this.formEl.nativeElement;
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 250);
  }

  onSubmitProduct(product) {
    console.log(product);
    this.showProductForm = false;
  }

  onProductCreated(product) {
    console.log(product);
    this.showNewProductForm = false;
    this.showProductForm = false;
    this.selectedProductLine = new ProductLine();
    this.adminService.getProductLines().subscribe(
      () => {
        // gotten
        this.loading.productLines = false;
      }
    );
  }

  onProductUpdated(product) {
    console.log(product);
    this.showNewProductForm = false;
    this.showProductForm = false;
    this.selectedProductLine = new ProductLine();
    this.adminService.getProductLines().subscribe(
      () => {
        // gotten
        this.loading.productLines = false;
      }
    );
  }

  onCloseProductForm(doClose: boolean) {
    this.showProductForm = !doClose;
    // this.showProductForm = false;
    this.showNewProductForm = false;
  }

  deleteProduct(product) {
    if (window.confirm('Sicher, dass Sie diese Produktlinie löschen möchten?')) {
      this.adminService.deleteProductLine(product).subscribe(
        () => {
          // done
        }
      );
    }
  }





}
