import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

const { log } = console;

@Injectable()
export class NavService {

    navItems = [
        // { label: 'Übersicht', path: '/overview', name: 'overview' },
        { label: 'Kategorien', path: '/categories', name: 'categories' },
        { label: 'Produktlinien', path: '/product-lines', name: 'product-lines' },
        { label: 'Produkte', path: '/products', name: 'products' },
    ];
    activeNavItem = new BehaviorSubject<any>(null);


    constructor(
    ) { }


    setActiveNavItem(name) {
        this.navItems.forEach(
            (n) => {
                if (n.name === name) {
                    this.activeNavItem.next(n);
                }
            }
        );
    }


}
